body{
  background-color: #f5f5f5;
  margin: 7px;
}
.popup-headding {
  padding: 5px 15px;
  background-color: #f5f5f5;
}
.MuiTableCell-head {
  font-size: 13px !important;
  font-weight: 600 !important;
}